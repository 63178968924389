#mercadopagoconfig .form-control,
#mercadopagoconfig .form-control:focus,
#mercadopagoconfig .form-control:active,
#mercadopagoconfig .form-control:focus-visible {
  border-bottom: 1px solid var(--secondaryColor) !important;
  border-radius: 0%;
}
#mercadopagoconfig .btn {
  margin: 0;
  margin-left: 0.75rem;
}

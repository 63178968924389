
.baseStyle{
    display: "flex";
    flex-direction: "column";
    align-items: "center";
    padding: "20px";
    border-width: 2;
    border-radius: 2;
    border-color: "#eeeeee";
    border-style: "dashed";
    background-color: "#fafafa";
    color: "#bdbdbd";
    transition: "border .3s ease-in-out";
  };

 .activeStyle {
    border-color: "#2196f3";
  };

 .acceptStyle{
    border-color: "#00e676";
 }

 .rejectStyle{
    border-color: "#ff1744";
 }


 .modal-auto-height {
  display: flex;
  flex-direction: column;
height: 1200px;;
overflow-y: hidden;
}

.confirmation-modal{
  background-color:white !important;
}

.answer-text {
    resize: none;
    height: 8rem;
    border: 1px solid var(--secondaryColor) !important;
}
.modal-faq-config {
    height: 350px;
    margin-top: 10rem;
}
.answer-text:focus, .answer-text:active {
    outline: none !important;
    box-shadow: none !important;
}
.shipment-extra-info-modal .modal-content {
    height: auto;
}
.shipment-extra-info-modal textarea {
    min-height: 5rem;
}

.shipment-extra-info-modal .form-floating input:focus,
.shipment-extra-info-modal .form-floating select:focus,
.shipment-extra-info-modal .form-floating textarea:focus {
    border: 1px solid var(--primaryColor) !important;
    box-shadow: none;
}

.shipment-extra-info-modal .form-control {
    transition: none !important;
}
.shipment-extra-info-modal textarea.form-control:valid {
    background-image: none;
}
@media screen and (max-width: 768px) {
    .shipment-extra-info-modal button[type = "submit"] {
        font-size: 0.83em;
    }
}

.contact-details-modal.edit .carousel-item {
  height: 25rem;
}
.pac-container {
  background-color: #fff;
  z-index: 2001;
}
.modal {
  z-index: 2000;
}
.modal-backdrop {
  z-index: 1000;
}

.wizard-contact-button:enabled {
  background-color: var(--primaryColor) !important;
}
.google-map-container {
  height: 25rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 2rem !important;
}

.datepicker {
  padding: 0.375rem !important;
  text-align: center;
}

.google-map-container-accordion {
  width: 100%; /* Set the width to 100% to fill the entire container */
  height: 400px; /* Adjust the height as needed */
  margin: 0 auto; /* Center the map horizontally within the container */
}

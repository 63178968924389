.contact-details-title {
  text-transform: uppercase;
  font-weight: 700 !important;
}
.contact-details-modal .google-map-container {
  height: 25rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.contact-details-modal .modal-header {
  border-bottom: 3px solid var(--primaryColor) !important;
}
.contact-details-modal .modal-content {
  height: auto !important;
}
.contact-details-modal .form-control {
  height: 2.2rem;
}

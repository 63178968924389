.header-admin {
 position: fixed !important;
 top: 0;
 display: flex;
 align-items: flex-end;
}

.header-admin img {
 height: 50px;
 padding-left: 16px;
}

.admin-navbar {
 justify-content: center;
 height: 80px;
 align-items: center;
}

.admin-navbar li {
 width: 100%;
 list-style: none;
}

.navbar-collapse-Admin {
 position: fixed;
 top: 80px;
 left: 0;
 padding: 0 !important;
 height: 100%;
 background-color: var(--secondaryColor);
 /* box-shadow: inset -20px 0px 20px 10px #00377a45; */
 width: 10.3rem;
 margin: 0;
 align-items: flex-start;
 transition-duration: 1s;
 overflow: hidden;
 box-sizing: border-box;
}

.open {
 width: 260px;
 transition-duration: 1s;
}

h3 .d-none {
 transition: opacity 2s ease-out;
 opacity: 0;
}
.showw {
 opacity: 1;
}
nav-link:focus,
.nav-link:hover {
 text-shadow: 2px 2px 4px #000000;
 color: #e6e6e6 !important;
}

.header-admin .dropdown-menu {
 position: initial;
 left: -50%;
 background-color: var(--secondaryColor);
}
.dropdown-menu {
 position: initial;
}

.admin-navbar-right {
 margin: 0 2rem;
 text-align: center;
}
.admin-navbar h3 {
 margin: 0 16px;
 padding: 4px 8px 4px 0;
 font-size: 20px;
 transition-duration: 1s;
}
.behind {
 z-index: -5000;
}
.navbar-menu-Admin {
 position: absolute;
 top: 100px;
 left: -16px;
 height: 100vh;
 box-shadow: inset -20px 0px 20px 10px #00377a45;
 padding: 0 !important;
 width: 72px;
}
.admin-search {
 display: flex;
 margin: 0 16px;
 padding: 4px 8px 4px 0;
 flex-direction: row;
 align-content: center;
 justify-content: center;
 vertical-align: middle;
 align-items: center;
 width: 11rem;
 font-size: 20px;
 height: 32px;
}
.admin-toggler {
 text-align: left !important;
 margin-left: 1rem;
}
.mobile-menu-Admin .nav-link {
 color: var(--btnFontColor) !important;
}

@media screen and (max-width: 991px) {
 header-admin img {
  height: 60px;
 }
}

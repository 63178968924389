.scrollable-container {
  max-height: 200px; /* Set the desired maximum height */
  overflow-y: auto; /* Add a vertical scrollbar when needed */
}

.scrollable-item {
  max-height: 200px; /* Set the desired maximum height for each item */
  overflow-y: auto; /* Add a vertical scrollbar when needed */
  margin-bottom: 10px; /* Optional spacing between items */
}

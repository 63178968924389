.admin-navbar-options {
  background-color: transparent;
  flex-wrap: wrap;
  display: flex;
  width: 22vw;
  justify-content: flex-start;
  flex-direction: column;
  align-content: center;
  flex-wrap: inherit;
  width: fit-content;
  padding: 0.25rem 2.2rem !important;
  font-size: 1.25rem;
  margin-left: 0.5rem;
}

.admin-navbar-options li h3,
.admin-navbar-options li svg {
  font-size: 1rem;
  color: var(--btnFontColor);
}

.admin-navbar-options h3 {
  margin: 0 8px;
  padding: 4px 8px 4px 0;
  font-size: 20px;
  transition-duration: 1s;
}

.collapsable-navbar-backdrop + .fade.modal.show {
  background-color: var(--primaryColor);
}

.collapsable-navbar-extended {
  width: 100vw !important;
  margin: 0 !important;
  max-width: 100vw;
}

.collapsable-navbar-extended .admin-navbar-options {
  margin: 2rem 0 0 2.5rem;
}

.collapsable-navbar-extended .modal-content {
  border: none !important;
  background-color: transparent;
}

.collapsable-navbar-extended .exit-menu {
  height: 1.8rem;
  border: none;
  background-color: transparent;
  color: rgb(255, 255, 255) !important;
}

.collapsable-navbar-extended .exit-menu svg {
  height: 100%;
  width: 100%;
}

.collapsable-navbar-extended .modal-content li h3 {
  font-weight: 700 !important;
  margin-bottom: 1rem;
  color: rgb(232, 230, 227) !important;
  font-size: calc(1.3rem + 0.6vw);
}

.collapsable-navbar-extended .admin-navbar-options li svg {
  display: none;
}

@media screen and (min-width: 992px) {
  .admin-navbar-options li svg {
    margin-bottom: 0.5rem;
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


body {
  overscroll-behavior: none;
  background-color: var(--background-color);

}

main {
  margin-top: 80px;
  padding: 1rem 0rem 5rem 0rem;
  box-sizing: border-box;
  background-color: var(--background-color);
  margin-bottom: 20px !important;
  width: auto;
  overscroll-behavior: none;

}

.site-header {
  background-color: var(--primaryColor);
  justify-content: space-between;
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  box-shadow: inset 0px 0px 20px 10px var(--shadowNavColor);
}

/* header img {
  height: 90px;
} */
@media screen and (min-width: 992px) {
  .admin-con {
    margin-left: 10.3rem;
    margin-right: 0;
    display: flex;
    justify-content: center;
    max-width: calc(100% - 10rem);
    padding: 0.5 1rem !important;
    scroll-behavior: auto !important;
    margin-bottom: 3rem;
    padding-bottom: 2rem !important;

  }

  .admin-con>div {
    width: 90%;
    scroll-behavior: auto !important;

  }

  .map-cont {
    width: 100% !important;
  }
}

.admin-con {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  scroll-behavior: auto !important;
  margin-bottom: 3rem !important;
  padding-bottom: 2rem !important;
  min-height: fit-content !important;


}

header a {
  font-weight: 300;
}

.navbar {
  width: 100vw;
  justify-content: center;
  height: 90px;
  align-items: flex-end;
}

.navbar ul {
  padding: 0.2rem 0.9rem
}

.navbar li {
  margin-right: 0.25rem;
}

.navbar-brand {
  text-align: left;
}

.socialNavbarLinks {
  justify-content: flex-end !important;
  padding-right: 0;
}

.socialNavbarLinks-top {
  /* justify-content: flex-end !important; */
  padding-right: 0;
  border: 0 !important;

}

.socialNavbarLinks-top li {
  padding: 0.1rem 0 !important;
}

.navbar {
  flex-direction: row;
}

.nav {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  align-content: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  align-items: unset;
  width: fit-content;
}

.nav-link {
  display: -webkit-inline-box;
  align-items: center;
  padding: 4px 8px;
  color: var(--navFontColor) !important;
  height: 32px;
  padding-left: 0px;
  padding-right: 0.5rem;
  font-weight: 700;
  width: fit-content;
}

.ulnavbar li {
  margin: 0 1rem;
}

nav-link:focus,
.nav-link:hover {
  color: var(--background-color) !important;
  filter: drop-shadow(2px 2px 4px white);
}

.badge {
  padding: 0.25em;
  margin-left: -6px;
}

.dropdown-menu {
  background-color: var(--secondaryColor);
  text-align: center;
  padding: 0.2rem !important;
}

.dropdown-item {
  width: 100%;
  text-align: left;
  padding: 0.3rem !important;
}

#basic-nav-dropdown {
  padding: 8px 8px 4px 15px;
  text-align: right;
  justify-content: flex-end;
  min-width: 120px;
}

.dropdown-item.active,
.dropdown-item:active {
  padding: 0.5rem 0.5rem;
}

.nav-item.dropdown {
  height: 32px !important;
  text-align: center !important;
}

i {
  color: var(--IconsColor)
}

.mobile-menu i {
  color: var(--btnFontColor) !important;
}

.product {
  border-radius: 10%;
  text-align: center;
}



.col-12 {
  padding: 0px;
}

.card-body .product-card-body {
  height: 12rem;
  margin: 0px;
}

.screenRow {
  margin: 1rem auto;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: var(--secondaryColor) !important;
  color: var(--btnFontColor) !important;
  border: none !important;
  margin: 0 auto;
  outline: 0;
}

.btn-primary:hover {
  /* text-shadow: 2px 2px 4px #000000;
  box-shadow: 1px 1px 2px #000000; */
  filter: brightness(95%);
}

.eyeButton {
  text-shadow: 2px 2px 4px #000000;
  border-left: none;
  margin-left: 0 !important;
}

.eyeButton:focus {
  box-shadow: none !important;
}

.product-button:hover {
  background-color: var(--secondaryColor) !important;
}

.envios-img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.mobile {
  display: contents;
}

.mobile-menu {
  flex-direction: row;
  flex-wrap: inherit;
  width: fit-content;
  color: var(--btnFontColor) !important;

}

.navbar-brand {
  justify-content: flex-start;
  margin: -0.7rem;
}

.navbar-logo {
  height: 50px !important;
}

li {
  margin: 0;
  padding: 4px 0px;
  width: unset;
}

.rightlinks {
  width: 20%;
  justify-content: flex-end;
}

.footer-bs {
  width: 100%;
  bottom: 40px;
  left: 0;
  padding: 2rem 1rem;
  background-color: var(--primaryColor);
  display: flex;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
  width: 100vw;
  box-shadow: inset 0px 0px 20px 10px var(--shadowNavColor);

}

.footerbrand {
  background-color: var(--primaryColor);
  border-right: var(--secondaryColor) 1px solid;
}

footer .col-12 {
  background-color: var(--primaryColor);
}

footer ul {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0;
}

footer li {
  list-style-type: none;
  line-height: normal;
  text-align: justify;
  margin: 0;
  font-size: 0.9rem;
}

footer li:hover {
  text-shadow: 2px 2px 4px #000000;
  color: var(--background-color) !important;
}

footer p {
  text-align: left;
  color: var(--navFontColor);
  font-size: medium;
  font-weight: 600;
  font-size: 1rem;
}

footer h5 {
  text-align: left;
  color: var(--navFontColor);
  font-weight: 800;
  margin-bottom: 0;
}

footer img {
  width: 5rem;
  margin: 1rem 0;
}

footer .col-12 {
  padding: 0.1rem 0.2rem;
}

.social-links {
  display: flex;
  flex-direction: row !important;
  margin: 0;
  justify-content: center;
}

.social-links li {
  width: fit-content;

  padding: 0 1rem;
}

footer .nav-link {
  display: -webkit-inline-box;
  align-items: center;
  color: var(--navFontColor) !important;
  height: auto !important;
  font-weight: 700;
}

.footer-sub {
  font-weight: 800;
  padding-top: 5px;

}

.footerbrand-sub {
  font-weight: 800 !important;
}

.footerbrand-info {
  font-size: .9rem;
}

footer .row {
  justify-content: center;
  align-content: center;
  width: 100%;
  margin: 0;
}

.navbar-expand-lg .navbar-nav {
  margin: 0 1rem;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 42.4px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.eyeButton {
  background: white;
  border-bottom: 2px solid var(--secondaryColor);
  padding: 0.5px 1rem;
  height: 32px;
  outline: 0 !important;

}

.productScreenContainer {
  padding: 3rem;
}

h1 svg {
  font-size: 3rem;
  margin-right: 1rem;
}

@media screen and (min-width: 992px) {
  .prod-cat-col {
    width: 20% !important;
  }

  .nav-link .d-lg-block {
    padding-left: 2.5px !important;
  }

}

@media screen and (max-width: 991px) {
  .mobile-menu {
    flex-wrap: wrap;
    display: flex;
    width: 20vw;
    justify-content: flex-start;
    flex-direction: column;
    align-content: center;
  }

  .navbar {
    flex-wrap: nowrap;
    height: 80px;
    width: 100vw;
    align-items: center;

  }

  .navbar-collapse-Admin {
    justify-content: unset !important;
    width: 0 !important;


  }

  .navbar-collapse-Admin.open {
    justify-content: unset !important;
    width: 260px !important;

  }

  .ulnavbar li {
    margin: 0 0.1rem;
  }

  .mobile-menu-admin li {
    margin-left: 1rem;
  }

  .navbar-collapse {
    position: fixed;
    top: 80px;
    left: 0;
    flex-grow: 0 !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: 100%;
    background-color: var(--secondaryColor);
  }



  .navbar-collapse.collapsing {
    left: -75%;
    transition: height 0s ease;
  }

  .rightlinks {
    width: 32px;
    justify-content: flex-end;
  }

  .navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
  }

  .navbar-toggler.collapsed~.navbar-collapse {
    transition: left 500ms ease-in-out;
    flex-grow: 0 !important;

  }

  footer p {
    text-align: center;
  }

  .nav-link {
    display: inline-flex;
  }



  .rounded-pill {
    transform: scale(0.8);
    margin-left: -0.5rem;
    display: inline-flex;
  }

  .Searchbox {
    display: inline-flex;
  }

  header img {
    height: 50px;
  }

  .navbar-brand {
    justify-content: flex-start;
    margin: auto;
    margin-left: 2rem;
  }


  .dropdown-item {
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
  }

  .input-group {
    padding: 4px 4px !important;
  }


  #basic-nav-dropdown {
    padding: 12px 4px 0px 0px;
    text-align: right;
    justify-content: flex-end;
    min-width: unset;
    font-size: 0.8rem;
  }

  .nav-item.dropdown {
    height: 32px !important;
    text-align: right !important;
  }

  .footer-bs {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  footer h5 {
    text-align: center;
  }

  footer li {
    text-align: center;
  }

  footer ul {
    border-bottom: var(--secondaryColor) 1px solid;
  }

  .footerbrand {
    border-bottom: none;
    border-right: none !important;
    display: flex;
    flex-direction: column;
    order: 2;
  }

  .footerbrand-img {
    order: 1;
  }

  footer img {
    margin: auto;
    display: block;
    float: none;
  }

  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }

  .productScreenContainer {
    padding: 1rem !important;
  }
}

.centered {
  justify-content: center;
  align-items: center;
}

.anularCero {
  color: var(--background-color);
}

.calculatorButton {
  color: white;
  background-color: var(--primaryColor);
  width: 60%;
  justify-content: center;
  align-items: center;
}

.calculatorButton:hover {
  background-color: var(--shadowNavColor);
}

#map_canvas {
  width: 300px;
  height: 300px;
}

.datepicker {
  margin-left: 5px;
  width: 95%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border: 2px solid #dee2e6 !important;
  border-radius: 0.25rem !important;

}

.borderLine {
  width: 100%;
  border: var(--primaryColor) 3px solid;
  background-color: var(--primaryColor);
}

.row-link {
  display: contents;
  cursor: pointer;
}

.tableHeader {
  font-size: medium;
  text-align: center;
  margin: auto;
  padding: 0.9rem !important;
  border: #0b0743 !important;
  border-radius: 2px !important;
}

.tHead {
  border-bottom: 2px solid #000000 !important;
}

.tableBody {
  font-size: small;
  text-align: center;
  vertical-align: middle;
  margin: auto;
  margin-top: 5px !important;
  padding: 0.75rem !important;
}

.tHover {
  font-size: small;
  text-align: center;
  vertical-align: middle;
  margin: auto;
  margin-top: 5px !important;
  padding: 0.75rem !important;
}

.tHover:hover {
  background-color: rgba(224, 224, 224) !important;
}


.tableBodyHover {
  border: #e8e7e7 0.2px solid !important;
  justify-content: center !important;
  overflow-x: scroll;
}



.pointerCursor {
  cursor: pointer;
}

.typeName {
  font-size: small;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  padding: 0.5rem !important;
}

.dpLabel {
  text-align: center;
  font-size: small;
  margin: 0 !important;
  padding: 0 !important;
}

.detailsButton {
  background-color: rgb(176, 176, 176) !important;
  color: #323131 !important;
}

.detailsButton:hover {
  background-color: var(--primaryColor) !important;
  color: var(--navFontColor) !important;
}

.cancelButton {
  background-color: rgb(176, 176, 176) !important;
  color: #e31f1f !important;
  border: #e31f1f 0.2px solid !important;
}

.cancelButton:hover {
  background-color: #e31f1f !important;
  color: white !important;
  border: white 0.2px solid !important;
}

.seeDetailsButton {
  background-color: rgb(209, 209, 209) !important;
  color: #1c92b0 !important;
  border: #1c92b0 0.2px solid !important;
}

.seeDetailsButton:hover {
  background-color: #1c92b0 !important;
  color: white !important;
  border: white 0.2px solid !important;
}

.btnCompleteShipment {
  background-color: rgb(222, 239, 219) !important;
  color: #000000 !important;
  border: #000000 0.2px solid !important;
}

.btnCompleteShipment:hover {
  background-color: #76ea72 !important;
  color: white !important;
  border: white 0.2px solid !important;
}


.btnNewShipment {
  background-color: #349056f0 !important;
  color: white !important;
  border: white 0.2px solid !important;
  width: 15rem;
}

.btnNewShipment:disabled {
  background-color: #b1b4b2 !important;
  color: rgb(8, 7, 7) !important;
  border: white 0.2px solid !important;
}

.btnNewShipment:hover {
  background-color: #97d1aa !important;
  color: rgb(8, 7, 7) !important;
  border: white 0.2px solid !important;
}

.table-container {
  width: 100%;
  /* overflow-y: scroll; Enable vertical scrolling */
  overflow-y: scroll;
  /* Enable vertical scrolling */
  overflow-x: scroll;
  /* Enable vertical scrolling */
  cursor: pointer;
}

.fixed-table {
  width: inherit;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
}

.acc-container {
  margin-bottom: 3px;
  background-color: transparent !important;
  border: none;
}

.priceByZone {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  font-size: x-small;
  text-align: center;
}

.modal-auto-height {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  height: auto;
}

@media (min-width: 992px) {
  .card-container {
    margin-left: 15px;

  }
}
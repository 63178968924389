.titles {
    margin: 1rem !important;
    font-family:
        Inter var,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        Noto Sans,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color emoji;
    color: var(--titlesColor);
    font-size: 2rem;
}

.col-md-3 {
    border-radius: 3%;
    background-color: transparent !important;
}

.title-col {
    padding: 16px;
}

.col-envios-contact {
    padding: 2rem;
}

.col-envios-contact li {
    font-size: 14px;
    padding: 0;
}

.btn-light {
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-light:hover {
    text-shadow: 2px 2px 4px #000000;
    background-color: transparent !important;
    border-color: transparent !important;
}

.btn-light:active {
    border: transparent;
}

.col-admin {
    text-align: center;
    padding: 0.25rem;
    margin: 1rem auto;
    box-shadow: 0px 0.5px 1px 0px rgb(0 0 0 / 50%);
}

.col-admin svg {
    vertical-align: middle;
    font-size: 2rem;
    margin-left: 50%;
}

.table-users {
    margin: 1px auto;
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
    align-items: center;
    text-align: center;
}

.table-users .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.orders-table {
    justify-content: space-between;
}

.container-admin {
    margin: 6rem;
}

.con-data {
    overflow-y: scroll;
    height: 65vh;
}

.con-data:focus {
    border: black 10px;
}

li {
    list-style: none;
}

/* timeline */
.comments-admin {
    margin: 100px auto;
    width: 60%;
    border-left: solid 2px #ccc;
    padding: 0px 20px 0px 20px;
}

.comments-admin p {
    background-color: #fff;
    padding: 10px;
    font-size: 16px;
    font-family:
        Inter var,
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        Noto Sans,
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color emoji;
    border: solid 1px #ccc;
    line-height: 1.7;
    position: relative;
}

.comments-admin p::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    display: block;
    border: 3px solid #ccc;
    border-radius: 50%;
    background-color: #2c3e50;
    top: 10px;
    left: -30px;
}

.comments-admin p::after {
    content: '';
    position: absolute;
    border: solid 8px;
    border-color: transparent #ccc transparent transparent;
    top: 10px;
    left: -17px;
}

.adminProdBtnRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btn-prod-admin {
    width: fit-content;
    margin: 0.5rem 0.5rem;
}

.btn:disabled {
    color: grey;
    border-color: grey;
}

#goBack {
    background-color: var(--utilsColor) !important;
    border: none;
}

.container-envios {
    text-align: justify;
}

.table-order {
    justify-content: space-around;
    font-size: small;
}

.table-order .btn {
    font-size: small;
}

.table-products {
    justify-content: space-around;
}

.table-products nav-link {
    text-shadow: 2px 2px 4px #000000;
    color: #e6e6e6 !important;
}

.label-info .tag {
    padding: 1px 5px !important;
}

.label-info {
    padding: 0.1rem 0.2rem !important;
}

.label-info .btn {
    padding: 0.05rem 0.2rem !important;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.pagination .page-item {
    width: auto;
}

.pagination .page-link {
    background-color: var(--background-color) !important;
    cursor: pointer;
    border: none;
}

.page-link:hover {
    background-color: var(--secondaryColor) !important;
}

@media screen and (max-width: 991px) {
    .pagination {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .adminProdBtnRow {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
    }
}
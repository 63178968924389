.accordion-header-input {
  background-color: transparent !important;
  border: none !important;
  font-weight: 600;
  padding: 0;
  font-size: 0.98rem !important;
  height: 100%;
}
#fleet-management-container {
  width: 90%;
}
/* FLEET MANAGEMENT CONTAINER */
#fleet-management-container {
  margin-top: 0.75rem;
  border-radius: 4px;
  padding: 0.1rem;
  flex-wrap: wrap;
  height: 80vh;
}

#fleet-management-container button {
  font-size: 0.95rem;
}

#fleet-management-container label {
  font-weight: 500;
}

#fleet-management-container section {
  padding: 0.5rem;
}

#fleet-management-container header {
  border-top: 6px solid var(--primaryColor);
  border-bottom: 6px solid var(--primaryColor);
  text-transform: uppercase;
  width: 100%;
  height: 10%;
  text-align: center;
}

#fleet-management-container h1 {
  font-size: 1.6rem;
  font-weight: 700;
}

#fleet-management-container h2 {
  font-size: 1.3rem;
  font-weight: 600;
}

#fleet-management-container text {
  background-color: #e9ecef;
}

#fleet-management-container .buttons-container {
  display: flex;
  justify-content: right;
}

#fleet-management-container button svg {
  margin: 0 0.2rem 0.12rem 0;
}

#fleet-management-container .accordion-header button {
  padding: 0.25rem;
}

#fleet-management-container #write-zone-section {
  padding: 0;
}

#fleet-map-container {
  height: 35vw;
  width: 100%;
}

#fleet-content-container {
  padding: 0.5rem;
  height: 90%;
  padding-left: 1.2rem;
}

#delivery-zone-edit {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

/* ZONE INFO ASIDE */
#zone-info-aside {
  overflow-y: scroll;
  padding-right: 0;
  max-height: 100%;
  flex-wrap: wrap;
  border-radius: 0;
  border-left: 1px solid var(--secondaryColor);
}

#zone-info-aside .accordion-header {
  height: 35px;
  margin-left: 0.45rem;
}

#zone-info-aside .accordion-header {
  height: 40px;
}

#zone-info-aside .form-control,
#zone-info-aside .form-select {
  height: 30px;
  font-size: 0.9rem;
}

#zone-info-aside label {
  font-size: 0.92rem;
}

#zone-info-aside .accordion-button:focus {
  border: none;
  box-shadow: none;
}

#zone-info-aside h2 {
  height: fit-content;
}

#zone-info-aside .accordion {
  margin-top: 1rem;
  height: fit-content;
}

#new-zone-accordion-item button span {
  font-size: 0.98rem;
  font-weight: 600;
}

#new-zone-accordion-item button {
  height: 100%;
}
#select-vehicle-typeahead,
#selected-zone-vehicle-typeahead {
  margin-top: 1.8rem;
  width: 100%;
}
#new-option-typeahead-toggle {
  font-weight: 400;
  cursor: pointer !important;
}

@media screen and (max-width: 992px) {
  #fleet-content-container {
    padding-right: 0;
    max-height: 45%;
    padding-left: 1.3rem;
  }

  #fleet-content-container .dropdown-item {
    font-size: 0.85rem;
  }
  #fleet-content-container {
    padding-right: 0;
    width: 100%;
    max-height: 45%;
    padding-left: 1.3rem;
  }

  #zone-info-aside {
    border-left: none;
    width: 100%;
  }

  #zone-info-aside .accordion {
    width: 100;
  }

  #fleet-management-container {
    height: 100vh;
    max-width: 800px !important;
  }
}

/* #zone-info-aside-footer {
    margin-top: 3rem;
} */

.tableShipments-container {
  max-height: 300px; /* Set your desired maximum height */
  overflow-y: scroll; /* Enable vertical scrolling when content overflows */
  padding-bottom: 10px;
  margin-bottom: 3px !important;
  border: 1px solid #ccc; /* Add a border for the container */
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit-button {
  background-color: white;
  border: none;
}
#payment-config {
  width: 100%;
  margin: 0;
}
/* #payment-config button:disabled {
    
} */
.payment-details {
  resize: none;
  height: 8rem;
  border: 1px solid var(--secondaryColor) !important;
}
#payment-config .fa-edit {
  color: var(--lightGrey);
}
#payment-config .list-group {
  border-radius: 0;
}
#payment-config .list-group-item {
  border-right: none;
  border-left: none;
  padding: 0.2rem 0;
}
.modal-pay {
  height: 350px;
  margin-top: 10rem;
}
.modal-delete-pay {
  margin-top: 10rem;
}
.action-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.14);
  transition: 0.2s;
}
.action-button :hover {
  color: black;
  transition: 0.2s;
}
#payment-config .form-control,
#payment-config .form-control:focus,
#payment-config .form-control:active,
#payment-config .form-control:focus-visible {
  border-bottom: 1px solid var(--secondaryColor) !important;
  border-radius: 0%;
}
#lista-metodos {
  font-size: 1rem;
}
#label-activo {
  font-weight: 400;
  font-size: 0.75rem;
}
#pen-checkbox {
  font-size: 0.75rem;
  width: 5px;
}
#label-boton {
  width: 48%;
}
#new-method-btn {
  line-height: 25px;
}

.email-config .btn-plus {
  margin: 0;
}

.email-config .email {
  padding: 0.2rem 0.3rem;
}

.email-config {
  width: 100%;
  margin: 0;
}

/* estilos global o no */
.email-config .form-control,
.email-config .form-control:focus,
.email-config .form-control:active,
.email-config .form-control:focus-visible {
  border-bottom: 1px solid var(--secondaryColor) !important;
  border-radius: 0%;
}
.email-list {
  width: 68%;
  border-radius: 0;
}
.email-list .list-group-item {
  border-right: none;
  border-left: none;
  padding: 0;
}
.btn-plus :focus {
  outline: none !important;
}
/* media queries */
@media (min-width: 992px) {
  .email-config {
    max-width: 720px;
  }
}
@media (min-width: 720px) and (max-width: 991px) {
  .email-config {
    max-width: 620px;
  }
}

@media (min-width: 240px) and (max-width: 719px) {
  .email-config {
    max-width: 520px;
  }
}

.nav-toggler {
  border: 1px solid var(--IconsColor);
  width: 3.5rem;
  height: 2.6rem;
  padding: 0.2rem;
  border-radius: 5px;
  opacity: 0.6;
}
.nav-toggler svg {
  opacity: 1 !important;
  width: 100%;
  height: 100%;
  color: var(--IconsColor);
}

.nav-toggler button {
  padding: 0;
  border-radius: 5px;
  opacity: 1;
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.nav-toggler:focus-within {
  transition: 0.2s;
  box-shadow: 0 0 0 0.25rem var(--IconsColor);
}
@media screen and (min-width: 992px) {
  .admin-toggler {
    visibility: hidden;
  }
}

:root {
  --lightGrey: rgba(0, 0, 0, 0.166);
}

h1 {
  font-size: 1.9rem;
  font-weight: 600;
}
h2 {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
}
iframe {
  width: 100%;
  height: 100%;
}
.rowButton {
  display: flex;
  justify-content: right;
}
.btn.btn-danger {
  padding: 0;
  height: 2rem;
  width: 9rem;
  font-size: medium;
  margin-bottom: 1rem;
}
.btn.btn-danger i {
  margin-right: 2px;
}
.modal-content {
  height: 400px;
}
.accordion-body p {
  margin-bottom: 0;
}
.accordion-item {
  border: 0;
  border-top: 1px solid var(--lightGrey);
  border-bottom: 1px solid var(--lightGrey);
}
.accordion-item p {
  line-height: normal;
  font-weight: 300;
}
.accordion-item:last-of-type,
.accordion-item:first-of-type {
  border-radius: 0;
}
.accordion-button:not(.collapsed) {
  transition: 0.5s;
  color: var(--primaryColor);
  background-color: white;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.container-faq .accordion-button:focus {
  outline: 0;
  box-shadow: 0;
  background-color: white;
}

@media (min-width: 992px) {
  .container-faq {
    max-width: 850px;
  }
}
@media (min-width: 720px) and (max-width: 991px) {
  .container-faq {
    max-width: 660px;
  }
}
@media (min-width: 240px) and (max-width: 719px) {
  .container-faq {
    max-width: 520px;
  }
}

a {
  text-decoration: none;
  color: black;
}

.config-screen {
  margin-top: 1rem;
}
.accordion-header button {
  font-weight: 500;
  padding: 0.5rem;
}
.config-item {
  font-size: larger;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item {
  border: 1px solid var(--lightGrey);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

@media (min-width: 992px) {
  .config-items {
    max-width: 1000px;
  }
}

@media (min-width: 720px) and (max-width: 991px) {
  .config-items {
    max-width: 850px;
  }
}

@media (min-width: 240px) and (max-width: 719px) {
  .config-items {
    max-width: 700px;
  }
}

#toggle-use-agenda-form-group {
  margin: 0.4rem;
}
#toggle-use-agenda-container {
  margin: 0.25rem;
  border: 1px solid #cccecf;
  font-weight: 500;
  background-color: #dddddd62;
  border-radius: 0.25rem;
}
